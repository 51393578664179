/*
 * This looks like a hot mess but that's only because it's a conversion of the
 * script from Heap's website to work with a more restrictive CSP and to not
 * inject additional scripts.
 */
import {
	cookies
} from 'lib/cookies.js'

import {
	meta
} from 'lib/dom.js'

/* Begin Heap script */
window.heap = window.heap || []

window.heap.appid = meta('tracking.heap')
window.heap.config = {}

var n = function(e) {
	return function(){
		window.heap.push([e].concat(Array.prototype.slice.call(arguments,0)))
	}
}

var p = [
	'addEventProperties',
	'addUserProperties',
	'clearEventProperties',
	'identify',
	'resetIdentity',
	'removeEventProperty',
	'setEventProperties',
	'track',
	'unsetEventProperty'
]

for (var o = 0; o < p.length; o++) {
	window.heap[p[o]] = n(p[o])
}
/* End Heap script */

if (meta('tracking.user')) {
	window.heap.identify(meta('tracking.user'), 'ID')
}

if (window.location.pathname === '/login' && window.location.search.includes('logout')) {
	window.heap.resetIdentity()
}

if (window.location.pathname === '/account/dashboard' && cookies.heapUserPropertiesUpdated === undefined) {
	let userProperties = {
		Age: Number(meta('tracking.age')),
		'Join Date': meta('tracking.joined')
	}

	const spendable = Number(meta('tracking.spendable'))
	const total = Number(meta('tracking.total'))
	const active = Number(meta('tracking.activeCount'))

	if (!Number.isNaN(active)) {
		userProperties['# Cards Activated'] = active
	}

	if (!Number.isNaN(spendable)) {
		userProperties['Account Balance Spendable'] = spendable
	}

	if (!Number.isNaN(total)) {
		userProperties['Account Balance Total'] = total
	}

	window.heap.addUserProperties(userProperties)

	document.cookie = `heapUserPropertiesUpdated=${ Date.now() }; SameSite=Lax; path=/`
}

if (meta('tracking.spendable') && meta('tracking.total')) {
	let eventProperties = {}

	const spendable = Number(meta('tracking.spendable'))
	const total = Number(meta('tracking.total'))

	if (!Number.isNaN(spendable)) {
		eventProperties['Account Balance Spendable'] = spendable
	}

	if (!Number.isNaN(total)) {
		eventProperties['Account Balance Total'] = total
	}

	window.heap.clearEventProperties()
	window.heap.addEventProperties(eventProperties)
}

if (meta('tracking.heap.event')) {
	window.heap.track(meta('tracking.heap.event'), JSON.parse(meta('tracking.heap.properties')))
}
